
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EncounterComponent from "@/modules/encounter/EncounterComponent.vue";
import { getEncounters } from "@/api/encounter.api";
import { Encounter, Service } from "@/modules/encounter/encounter.model";

export interface Data {
  items: Encounter[];
  selectedItem?: { id: string; effectiveDate: Date; services: Service[] };
  panel: unknown;
}

export default defineComponent({
  name: "EncounterCRUDPage",
  components: { EncounterComponent },
  setup() {
    const url = "api/v1/encounters";

    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      selectedItem: { id: "", effectiveDate: new Date(), services: [] },
      panel: { facilities: [], patients: [], providers: [] },
    });

    data.selectedItem = undefined;

    function addMode() {
      data.selectedItem = undefined;
    }
    async function itemChanged() {
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Companies");
      await getAll();
    });

    async function getAll() {
      data.items = await getEncounters();
    }

    function selectItem(item) {
      data.selectedItem = item;
    }

    return {
      organizationId,
      data,
      selectItem,
      addMode,
      itemChanged,
    };
  },
});
