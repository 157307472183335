<template><div></div></template>
<!-- <template>
  <div v-if="data.encounter" class="card">
    <div class="card-header">
      <h3 v-if="data.encounter && !data.createNewMode" class="card-title">
        Edit
      </h3>
      <h3 v-else-if="!data.updateOnly" class="card-title">Add</h3>
      <div class="card-toolbar">
        <button
          v-if="data.encounter && !data.createNewMode"
          @click="updateItem()"
          type="button"
          class="btn ab-btn-uniform"
        >
          Update
        </button>
        <button
          v-else-if="!data.updateOnly"
          @click="addItem()"
          type="button"
          class="btn ab-btn-uniform"
        >
          Add
        </button>
      </div>
    </div>
    <div class="card-body">
      <div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Status</label>
          <div class="col-sm-6">
            <select
              class="form-select col-sm-6"
              v-model="data.encounter.status"
            >
              <option
                v-for="item in data.dict.encounterStatusCodes"
                v-bind:key="item.code"
                v-bind:value="item.code"
              >
                {{ item.description }}
              </option>
            </select>
          </div>
          <div
            class="input-errors"
            v-for="error of v$.encounter.status.$errors"
            :key="error.$uid"
          >
            <span class="text-danger">{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Encounter ID</label>
          <div class="col-sm-6">
            <input
              type="text"
              v-model="data.encounter.encounterId"
              class="form-control"
              id="name"
              disabled
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Patient</label>
          <div class="col-sm-6">
            <select
              class="form-select col-sm-6"
              v-model="data.encounter.patientId"
              :disabled="updateOnly || data.patientId"
            >
              <option
                v-for="item in data.panel.patients"
                v-bind:key="item.id"
                v-bind:value="item.id"
              >
                {{ item.lastName }}
              </option>
            </select>
          </div>
          <div
            class="input-errors"
            v-for="error of v$.encounter.patientId.$errors"
            :key="error.$uid"
          >
            <span class="text-danger">{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Service Facility Name</label>
          <div class="col-sm-6">
            <select
              class="form-select col-sm-6"
              v-model="data.encounter.facilityId"
            >
              <option
                v-for="item in data.panel.facilities"
                v-bind:key="item.id"
                v-bind:value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label"
            >Statement Covers Period From</label
          >
          <div class="col-sm-6">
            <DateComponent v-model="data.encounter.statementCoversPeriodFrom" />
          </div>
          <div
            class="input-errors"
            v-for="error of v$.encounter.statementCoversPeriodFrom.$errors"
            :key="error.$uid"
          >
            <span class="text-danger">{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label"
            >Statement Covers Period To</label
          >
          <div class="col-sm-6">
            <DateComponent v-model="data.encounter.statementCoversPeriodTo" />
          </div>
          <div
            class="input-errors"
            v-for="error of v$.encounter.statementCoversPeriodTo.$errors"
            :key="error.$uid"
          >
            <span class="text-danger">{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Admission Date</label>
          <div class="col-sm-6">
            <DateComponent v-model="data.encounter.admissionDate" />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Admission Hour</label>
          <div class="col-sm-6">
            <HourComponent v-model="data.encounter.admissionHour" />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Discharge Hour</label>
          <div class="col-sm-6">
            <HourComponent v-model="data.encounter.dischargeHour" />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Principal Diagnosis</label>
          <div class="col-sm-6">
            <multiselect
              valueProp="code"
              label="code"
              v-model="data.encounter.principalDiagnosis"
              :options="data.dict.diagnosisCodes"
              internal-search="true"
              searchable="true"
            >
            </multiselect>
          </div>
          <div
            class="input-errors"
            v-for="error of v$.encounter.principalDiagnosis.$errors"
            :key="error.$uid"
          >
            <span class="text-danger">{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Additional Diagnosis</label>
          <div class="col-sm-6">
            <multiselect
              mode="tags"
              valueProp="code"
              label="code"
              v-model="data.encounter.diagnosisList"
              :options="data.dict.diagnosisCodes"
              internal-search="true"
              searchable="true"
            >
            </multiselect>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label">Admitting Diagnosis</label>
          <div class="col-sm-6">
            <multiselect
              valueProp="code"
              label="code"
              v-model="data.encounter.admittingDiagnosis"
              :options="data.dict.diagnosisCodes"
              internal-search="true"
              searchable="true"
            >
            </multiselect>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-6 col-form-label"
            >Prior Authorization Number</label
          >
          <div class="col-sm-6">
            <input
              type="text"
              v-model="data.encounter.priorAuthorizationNumber"
              class="form-control"
              id="name"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <h4>Services</h4>
        <table class="table-sm table-row-gray" style="font-size: 12px">
          <thead>
            <tr class="fw-bolder fs-5 text-gray-800">
              <th>Rev Code</th>
              <th>Description</th>
              <th>HCPCS</th>
              <th>Modifiers</th>
              <th>Service Date</th>
              <th>Days Or Units</th>
              <th>Charge Per Unit</th>
              <th>Total Charges</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(service, index) in data.encounter.services"
              :key="service.id"
            >
              <td>
                <multiselect
                  valueProp="code"
                  label="code"
                  v-model="service.revenueCode"
                  :options="data.dict.revenueCodes"
                  internal-search="true"
                  searchable="true"
                  @select="revenueCodeChanged($event, service)"
                  style="minWidth: 100px"
                >
                </multiselect>
                <div
                  class="text-danger"
                  v-if="v$.encounter.services.$each.$message[index][0]"
                >
                  required
                </div>
              </td>
              <td>
                <input
                  type="text"
                  v-model="service.description"
                  class="form-control form-control-sm"
                  id="name"
                />
                <div
                  class="text-danger"
                  v-if="v$.encounter.services.$each.$message[index][1]"
                >
                  required
                </div>
              </td>
              <td>
                <multiselect
                  valueProp="code"
                  label="code"
                  v-model="service.hcpcs"
                  :options="data.dict.hcpcs"
                  internal-search="true"
                  searchable="true"
                  @input="hcpcsOnChange($event, service)"
                  style="minWidth: 110px"
                >
                </multiselect>
              </td>
              <td>
                <multiselect
                  :disabled="!service.hcpcs"
                  mode="tags"
                  valueProp="code"
                  label="code"
                  v-model="service.modifiersList"
                  :options="data.dict.hcpcsModifiers"
                  internal-search="true"
                  searchable="true"
                  max="4"
                  style="minWidth: 150px"
                >
                </multiselect>
              </td>
              <td>
                <DateComponent v-model="service.serviceDate" />
              </td>
              <td>
                <DecimalInput
                  v-model="service.daysOrUnits"
                  precision="3"
                  valueRange="10000000"
                />
              </td>
              <td>
                <DecimalInput
                  v-model="service.chargePerUnit"
                  precision="6"
                  valueRange="10000000"
                />
              </td>
              <td>
                <span>
                  {{ (service.daysOrUnits * service.chargePerUnit).toFixed(2) }}
                </span>
              </td>
              <td>
                <i class="bi bi-trash" @click="removeService(service)"></i>
              </td>
            </tr>
            <tr v-if="v$.encounter.services.$errors.length > 0">
              <h3 class="text-danger">Services Problem</h3>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <i class="bi bi-plus-circle fs-2x" @click="addService()"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <div v-if="data.encounter && !data.createNewMode" class="card-toolbar">
        <button
          v-if="!data.updateOnly"
          @click="switchMode()"
          type="button"
          class="btn btn-sm btn-light"
        >
          Add new
        </button>
        <button @click="deleteItem()" type="button" class="btn btn-danger">
          Delete
        </button>
        <button
          v-if="data.encounter && !data.createNewMode"
          @click="updateItem()"
          type="button"
          class="btn ab-btn-uniform float-end"
        >
          Update
        </button>
      </div>
      <button
        v-else-if="!data.updateOnly"
        @click="addItem()"
        type="button"
        class="btn ab-btn-uniform float-end"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import Multiselect from "@vueform/multiselect";
import ApiService from "@/core/services/ApiService";
import {
  addEncounter,
  getEncounter,
  deleteEncounter,
  updateEncounter,
  getPanel,
} from "@/api/encounter.api";

import { getDictionary, getRevenueDictionary } from "@/api/dictionaries.api";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { Service, Encounter } from "@/modules/encounter/encounter.model";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface Data {
  encounter?: Encounter;
  patientId: string;
  createNewMode: boolean;
  panel: unknown;
  updateOnly: boolean;
  dict: {
    hcpcs: [];
    revenueCodes: RevenueCode[];
    hcpcsModifiers: [];
    diagnosisCodes: [];
    encounterStatusCodes: [];
  };
}

export default defineComponent({
  name: "EncounterComponent",
  components: { Multiselect, DecimalInput },
  props: ["encounter", "updateOnly", "patientId"],
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      encounter: undefined,
      patientId: props.patientId,
      createNewMode: true,
      panel: { facilities: [], patients: [], providers: [] },
      updateOnly: false,
      dict: {
        hcpcs: [],
        revenueCodes: [],
        hcpcsModifiers: [],
        diagnosisCodes: [],
        encounterStatusCodes: [],
      },
    });

    const encounterRules = {
      encounter: {
        status: { required },
        patientId: { required },
        statementCoversPeriodFrom: { required },
        statementCoversPeriodTo: { required },
        principalDiagnosis: { required },
        services: {
          required,
          minLength: minLength(1),
          $each: helpers.forEach({
            revenueCode: { required },
            description: { required },
            serviceDate: { required },
            daysOrUnits: { required },
          }),
        },
      },
    };

    const v$ = useVuelidate(encounterRules, data as never);

    watch(
      () => props.encounter,
      () => {
        if (props.encounter) {
          data.encounter = props.encounter as Encounter;
          data.createNewMode = false;
        }
      }
    );

    function addService() {
      if (data.encounter) {
        let newService = {
          revenueCode: "",
          description: "",
          hcpcs: "",
          modifiers: "",
          serviceDate: new Date(),
          chargePerUnit: 0,
          daysOrUnits: 1,
        } as Service;
        data.encounter.services.push(newService);
      }
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      await getDict();
      data.panel = await getPanel(true);
      if (props.encounter) {
        data.encounter = props.encounter as Encounter;
        data.createNewMode = false;
      } else {
        switchMode();
      }
      if (props.updateOnly) {
        data.updateOnly = props.updateOnly;
      }
    });

    function revenueCodeChanged(code: string, service) {
      const item = data.dict.revenueCodes.find((elem) => {
        return elem.code == code;
      });
      if (item) {
        service.description = item.description;
        service.revenueCode = item.code;
        service.chargePerUnit = item.chargePerUnit;
      }
    }

    async function getDict() {
      data.dict.hcpcs = await getDictionary(0, "");
      data.dict.hcpcsModifiers = await getDictionary(2, "");
      data.dict.diagnosisCodes = await getDictionary(3, "");
      const res = await getRevenueDictionary("");
      data.dict.revenueCodes = res;
      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;
    }

    async function switchMode() {
      data.createNewMode = true;
      data.encounter = {
        id: "",
        effectiveDate: new Date(),
        services: [],
        patientId: props.patientId,
        facilityEncounter: null,
        professionalEncounter: null,
        encounterType: 0,
      };
      ctx.emit("addMode");
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addEncounter(data.encounter);
        data.createNewMode = false;
        ctx.emit("itemChanged");
      }
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        await updateEncounter(data.encounter);
        if (data.encounter) {
          data.encounter = await getEncounter(data.encounter.id);
        }
      }
    }

    async function deleteItem() {
      if (data.encounter) {
        await deleteEncounter(data.encounter.id);
        ctx.emit("itemChanged");
        ctx.emit("switchMode");
      }
    }

    function removeService(service) {
      data.encounter?.services.splice(
        data.encounter?.services.indexOf(service),
        1
      );
    }

    function hcpcsOnChange(event, service) {
      if (!event) {
        service.modifiersList = [];
      }
    }

    return {
      organizationId,
      data,
      addItem,
      updateItem,
      deleteItem,
      switchMode,
      addService,
      hcpcsOnChange,
      getDict,
      revenueCodeChanged,
      removeService,
      v$,
    };
  },
});
</script> -->
